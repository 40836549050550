import update from 'immutability-helper';
import { FETCH_MORE_ITEMS } from '../actions/moreItems';
import {
  ADD_RECENT_SEARCH,
  CLEAR_RECENT_SEARCHES,
  CLEAR_SEARCH,
  FETCH_PREPOPULATE_SEARCH,
  FETCH_SEARCH,
  GET_RECENT_SEARCHES,
  REMOVE_RECENT_SEARCH,
  searchResultsContentId,
  searchSuggestionsContentId,
} from '../actions/search';
import { fulfilled, pending, rejected } from './utils/asyncActionNameSuffixes';
import { containerNavigationUpdate } from './utils/createContainerNavigationHandlers';
import createMappedReducer from './utils/createMappedReducer';
import createRequestLifecycleHandlers from './utils/createRequestLifecycleHandlers';

// exported for unit testing
export const initialState = {
  recentSearches: [],
  canShowRecentSearches: false,
  [searchResultsContentId]: {},
  [searchSuggestionsContentId]: {},
};

function returnsSearchResultsForSuggestionsRequest(contentId, content) {
  return (
    contentId === searchSuggestionsContentId &&
    content?.containerItems?.[0]?.containerType !== 'SearchTermSuggestions'
  );
}

function updateRecentSearches(state, action) {
  return update(state, { recentSearches: { $set: action.recentSearches } });
}

export const search = createMappedReducer(initialState, {
  ...createRequestLifecycleHandlers({ fetchActionNameRoot: FETCH_SEARCH }),
  ...createRequestLifecycleHandlers({
    fetchActionNameRoot: FETCH_PREPOPULATE_SEARCH,
  }),
  [pending(FETCH_SEARCH)](state, action) {
    const { contentId, disableLoadingState, searchTerm } = action.meta;

    return update(state, {
      [contentId]: {
        $merge: {
          searchTerm,
          hasFailed: false,
          isFetching: !disableLoadingState,
        },
      },
    });
  },
  [fulfilled(FETCH_SEARCH)](state, action) {
    const { payload, meta } = action;
    const { contentId } = meta;
    const { content, loadedTime } = payload;
    const getsSearchResultsForSuggestionsRequest =
      returnsSearchResultsForSuggestionsRequest(contentId, content);

    const {
      children: suggestions,
      title,
      containerType,
    } = content?.containerItems?.[0] || {};

    return update(state, {
      canShowRecentSearches: { $set: false },
      [getsSearchResultsForSuggestionsRequest
        ? searchResultsContentId
        : contentId]: {
        $merge: {
          ...(containerType === 'SearchTermSuggestions'
            ? { suggestions, title }
            : content),
          loadedTime,
          isFetching: false,
        },
      },
      ...((getsSearchResultsForSuggestionsRequest ||
        contentId === searchResultsContentId) && {
        [searchSuggestionsContentId]: { $set: {} },
      }),
    });
  },
  [pending(FETCH_PREPOPULATE_SEARCH)](state, action) {
    const { contentId, disableLoadingState } = action.meta;

    return update(state, {
      canShowRecentSearches: { $set: true },
      [contentId]: {
        $merge: {
          searchTerm: '',
          hasFailed: false,
          isFetching: !disableLoadingState,
        },
      },
    });
  },
  [pending(FETCH_MORE_ITEMS)](state, action) {
    if (action.meta.contentId !== searchResultsContentId) return state;

    return update(state, {
      [action.meta.contentId]: {
        isFetching: { $set: true },
      },
    });
  },
  [rejected(FETCH_MORE_ITEMS)](state, action) {
    if (action.meta.contentId !== searchResultsContentId) return state;

    return update(state, {
      [action.meta.contentId]: {
        isFetching: { $set: false },
      },
    });
  },
  [fulfilled(FETCH_MORE_ITEMS)](state, action) {
    if (action.meta.contentId !== searchResultsContentId) return state;

    return update(state, {
      [action.meta.contentId]: containerNavigationUpdate(action),
    });
  },
  [ADD_RECENT_SEARCH]: updateRecentSearches,
  [GET_RECENT_SEARCHES]: updateRecentSearches,
  [REMOVE_RECENT_SEARCH]: updateRecentSearches,
  [CLEAR_RECENT_SEARCHES]: updateRecentSearches,
  [CLEAR_SEARCH]: (state, action) =>
    update(state, { [action?.contentId]: { $set: {} } }),
});

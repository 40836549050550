import update from 'immutability-helper';
import createMappedReducer from 'src/common/reducers/utils/createMappedReducer';
import { mintSingleton } from '../../client/mint';
import {
  CLOSE_DOWNLOAD_APP_DIALOG,
  CLOSE_NOW_PLAYING_DIALOG,
  OPEN_DOWNLOAD_APP_DIALOG,
  OPEN_NOW_PLAYING_DIALOG,
} from '../actions/dialog';
import { DISPLAY_SLOT_LOADED, MINT_INIT } from '../actions/mint';

const initialState = {
  isInitialized: false,
  deregisterDisplayAds: false,
  previousScreen: '',
  loadedDisplaySlotId: '',
};

export const mint = createMappedReducer(initialState, {
  // mint actions
  [MINT_INIT](state) {
    return {
      ...state,
      isInitialized: true,
    };
  },
  [DISPLAY_SLOT_LOADED](state, action) {
    return update(state, {
      loadedDisplaySlotId: { $set: action.elementId },
    });
  },

  // other actions
  [OPEN_NOW_PLAYING_DIALOG](state) {
    const previousScreen = mintSingleton.instance?.getState('currentScreen');
    mintSingleton.updateState('currentScreen', 'nowPlaying');
    return {
      ...state,
      deregisterDisplayAds: true,
      previousScreen,
    };
  },
  [CLOSE_NOW_PLAYING_DIALOG](state) {
    mintSingleton.updateState('currentScreen', state.previousScreen);
    return {
      ...state,
      deregisterDisplayAds: false,
      previousScreen: '',
    };
  },
  [OPEN_DOWNLOAD_APP_DIALOG](state) {
    return {
      ...state,
      deregisterDisplayAds: true,
    };
  },
  [CLOSE_DOWNLOAD_APP_DIALOG](state) {
    return {
      ...state,
      deregisterDisplayAds: false,
    };
  },
});

import update from 'immutability-helper';
import { FETCH_MORE_ITEMS } from 'src/common/actions/moreItems';
import { fulfilled, pending, rejected } from './asyncActionNameSuffixes';

export function containerNavigationUpdate(action, moreItemsIndexName) {
  const {
    meta: { containerIndex },
  } = action;
  const {
    payload: { content },
  } = action;

  const finalMoreItemsIndexName = moreItemsIndexName || 'containerItems';

  return {
    isFetching: { $set: false },
    [finalMoreItemsIndexName]: {
      [containerIndex]: {
        containerNavigation: { $set: content.containerNavigation },
        children: { $push: content.guideItems },
      },
    },
  };
}

export default function createContainerNavigationHandlers() {
  return {
    [pending(FETCH_MORE_ITEMS)](state, action) {
      if (!state[action.meta.contentId]) return state;
      return update(state, {
        [action.meta.contentId]: {
          isFetching: { $set: true },
        },
      });
    },
    [rejected(FETCH_MORE_ITEMS)](state, action) {
      // what should we do when a load more request fails?
      if (!state[action.meta.contentId]) return state;
      return update(state, {
        [action.meta.contentId]: {
          isFetching: { $set: false },
        },
      });
    },
    [fulfilled(FETCH_MORE_ITEMS)](state, action) {
      const item = state[action.meta.contentId];

      if (!item) {
        return state;
      }

      // TODO: clean this up once we split/refactor user actions/reducers
      const moreItemsIndexName = item.moreItemsIndexName
        ? item.moreItemsIndexName
        : null;

      return update(state, {
        [action.meta.contentId]: containerNavigationUpdate(
          action,
          moreItemsIndexName,
        ),
      });
    },
  };
}

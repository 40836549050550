import { HIDE_MESSAGE, SHOW_MESSAGE } from '../actions/messagePopover';
import createMappedReducer from './utils/createMappedReducer';

export function getInitialState() {
  return {
    message: '',
    showing: false,
  };
}

function showMessage(state, action) {
  return {
    ...state,
    message: action.message,
    showing: true,
  };
}

function hideMessage(state) {
  return {
    ...state,
    showing: false,
  };
}

export const messagePopover = createMappedReducer(getInitialState(), {
  [SHOW_MESSAGE]: showMessage,
  [HIDE_MESSAGE]: hideMessage,
});

import url from 'url';
import PropTypes from 'prop-types';
import { createContext } from 'react';
import parseQuery from 'src/common/utils/queryString/parse';
import format from 'string-format';
import isServer from '../utils/isServer';
import { safeJsonStringify } from '../utils/object/safeJsonStringify';

const LocationAndLocalizationContext = createContext();
LocationAndLocalizationContext.displayName = 'LocationAndLocalizationProvider';

const LocationAndLocalizationProvider = ({
  locale = '',
  location = {},
  localizations = {},
  children,
}) => {
  const locationObj =
    (location.pathname !== undefined && url.parse(location.pathname)) || null;
  const query = parseQuery(location.search);

  const getLocalizedText = (name, values) => {
    const text = localizations[name];

    if (text) {
      return (values ? format(text, values) : text) || name;
    }

    // Only log localization issues on the server
    if (!isServer()) {
      return name;
    }

    const message = `'${name}' is not defined in translations.`;
    // eslint-disable-next-line no-console
    console.warn(
      message,
      typeof name === 'string' ? '' : safeJsonStringify(name, 2),
    );

    return name;
  };

  const value = {
    locale,
    location: {
      ...locationObj,
      query,
      search: location.search,
      state: location.state,
    },
    getLocalizedText,
  };

  return (
    <LocationAndLocalizationContext.Provider value={value}>
      {children}
    </LocationAndLocalizationContext.Provider>
  );
};

LocationAndLocalizationProvider.propTypes = {
  locale: PropTypes.string,
  location: PropTypes.object,
  localizations: PropTypes.object,
  children: PropTypes.node.isRequired,
};

export default LocationAndLocalizationProvider;
export { LocationAndLocalizationContext };

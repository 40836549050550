import { SET_PLAYBACK_HISTORY } from '../constants/playbackHistory';

const initialState = {};

export default function (state = initialState, action = {}) {
  switch (action.type) {
    case SET_PLAYBACK_HISTORY:
      return {
        ...state,
        [action.payload.id]: action.payload.progressPercentage,
      };
    default:
      return state;
  }
}

import { SHOW_AVAILABLE_DESKTOP_UPDATE_BANNER } from '../actions/desktop';
import createMappedReducer from './utils/createMappedReducer';

export const initialState = {
  shouldShowDesktopUpdateBanner: false,
  showManualUpdateBanner: false,
};

export const desktop = createMappedReducer(initialState, {
  [SHOW_AVAILABLE_DESKTOP_UPDATE_BANNER](state, action) {
    return {
      shouldShowDesktopUpdateBanner: true,
      showManualUpdateBanner: action.showManualUpdateBanner,
    };
  },
});

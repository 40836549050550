import update from 'immutability-helper';
import get from 'lodash/get';
import {
  ALEXA_CHECK_STATUS,
  ALEXA_GET_URLS,
  ALEXA_LINK_FAIL,
  ALEXA_LINK_SUCCESS,
  FETCH_PARTNER,
  LINK_SONOS,
} from '../actions/partners';
import {
  config,
  linkStatusMap,
  skillStatusMap,
} from '../constants/partners/alexa';
import addQuery from '../utils/queryString/addQuery';
import vars from '../vars';
import { fulfilled, pending, rejected } from './utils/asyncActionNameSuffixes';
import createMappedReducer from './utils/createMappedReducer';
import createRequestLifecycleHandlers from './utils/createRequestLifecycleHandlers';

const sonosPartnerId = vars.get('SONOS_PARTNER_ID');

const initialState = {
  alexa: {
    isLinking: false,
    isLinked: false,
    hasFailed: false,
    urlFetchFail: false,
  },
  [sonosPartnerId]: {},
};

// exported for testing
export const isAlexaLinked = (data) => {
  const linkStatus = get(data, 'payload.linkStatus');
  const skillStatus = get(data, 'payload.skillStatus');

  return (
    linkStatus === linkStatusMap.linked &&
    (skillStatus === skillStatusMap.enabled ||
      skillStatus === skillStatusMap.enabling)
  );
};

function handleAlexaLinkPending(state) {
  return update(state, {
    alexa: {
      isLinking: { $set: true },
    },
  });
}

function handleAlexaLinkFail(state) {
  return update(state, {
    alexa: {
      hasFailed: { $set: true },
      isLinking: { $set: false },
    },
  });
}

function handleAlexaLinkSuccess(state, data) {
  if (isAlexaLinked(data)) {
    return update(state, {
      alexa: {
        isLinked: { $set: true },
        isLinking: { $set: false },
      },
    });
  }
  return handleAlexaLinkFail(state);
}

function handleAlexaStatusRejection(state) {
  return update(state, {
    alexa: {
      isLinked: { $set: false },
    },
  });
}

function handleAlexaStatusSuccess(state, data) {
  if (isAlexaLinked(data)) {
    return update(state, {
      alexa: {
        isLinked: { $set: true },
      },
    });
  }
  return handleAlexaStatusRejection(state);
}

function handleAlexaUrlsFailure(state) {
  return update(state, {
    alexa: {
      urlFetchFail: { $set: true },
    },
  });
}

function handleAlexaUrls(state, data) {
  const serial = get(data, 'meta.serial');
  const lwaFallBackUrl = get(data, 'payload.url');

  if (lwaFallBackUrl) {
    const amazonUrl = addQuery(lwaFallBackUrl, {
      redirect_uri: config.redirectUrl,
      state: serial,
    });
    const uriWithQuery = addQuery(config.alexaAuthorizeUrl, {
      redirectUrl: amazonUrl,
      state: serial,
    });
    return update(state, {
      alexa: {
        lwaUrl: { $set: uriWithQuery },
        urlFetchFail: { $set: false },
      },
    });
  }
  return handleAlexaUrlsFailure(state);
}

function handleSonosSuccess(state) {
  return update(state, {
    [sonosPartnerId]: {
      isLinked: { $set: true },
      isLinking: { $set: false },
    },
  });
}

function handleSonosPending(state) {
  return update(state, {
    [sonosPartnerId]: {
      isLinking: { $set: true },
    },
  });
}

function handleSonosFailure(state) {
  return update(state, {
    [sonosPartnerId]: {
      hasFailedLinking: { $set: true },
      isLinked: { $set: false },
      isLinking: { $set: false },
    },
  });
}

export const partners = createMappedReducer(initialState, {
  ...createRequestLifecycleHandlers({ fetchActionNameRoot: FETCH_PARTNER }),
  [pending(LINK_SONOS)]: handleSonosPending,
  [fulfilled(LINK_SONOS)]: handleSonosSuccess,
  [rejected(LINK_SONOS)]: handleSonosFailure,
  [pending(ALEXA_LINK_SUCCESS)]: handleAlexaLinkPending,
  [fulfilled(ALEXA_LINK_SUCCESS)]: handleAlexaLinkSuccess,
  [rejected(ALEXA_LINK_SUCCESS)]: handleAlexaLinkFail,
  [fulfilled(ALEXA_CHECK_STATUS)]: handleAlexaStatusSuccess,
  [rejected(ALEXA_CHECK_STATUS)]: handleAlexaStatusRejection,
  [fulfilled(ALEXA_GET_URLS)]: handleAlexaUrls,
  [rejected(ALEXA_GET_URLS)]: handleAlexaUrlsFailure,
  [ALEXA_LINK_FAIL]: handleAlexaLinkFail,
});

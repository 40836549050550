import update from 'immutability-helper';
import createMappedReducer from 'src/common/reducers/utils/createMappedReducer';
import { SET_BREADCRUMB_ID, SET_PAGE_LOAD_ID } from '../actions/reporting';

export const initialState = {
  pageLoadId: null,
  breadcrumbId: null,
};

export const reporting = createMappedReducer(initialState, {
  [SET_PAGE_LOAD_ID](state, action) {
    return update(state, {
      pageLoadId: { $set: action.pageLoadId },
    });
  },
  [SET_BREADCRUMB_ID](state, action) {
    return update(state, {
      breadcrumbId: { $set: action.breadcrumbId },
    });
  },
});

import update from 'immutability-helper';
import get from 'lodash/get';
import createMappedReducer from 'src/common/reducers/utils/createMappedReducer';
import asyncStatuses from 'src/common/utils/asyncStatuses';

import {
  CANCEL_SUBSCRIPTION,
  CLEAR_SETUP_INTENT_STATE,
  CLEAR_SUBSCRIPTION_CURRENT_STATE,
  CREATE_SETUP_INTENT,
  CREATE_SUBSCRIPTION,
  CREATE_SUBSCRIPTION_USER,
  FETCH_COUPON,
  GET_SIGNATURE,
  GET_SUBSCRIPTION,
  LINK_EXISTING_SUBSCRIPTION,
  POST_RECEIPT_TO_REV_CAT,
  RENEW_SUBSCRIPTION,
  UPDATE_LOCAL_CUSTOMER_ADDRESS,
} from '../actions/subscription';

import { LOGOUT } from '../actions/auth';

import {
  GET_LOCATION,
  LOCATION_NOT_FOUND,
  SET_LOCATION,
  SET_LOCATION_OPTIONS,
} from '../actions/location';
import { fulfilled, pending, rejected } from './utils/asyncActionNameSuffixes';
import createRequestLifecycleHandlers from './utils/createRequestLifecycleHandlers';

const initialState = {
  current: {},
};

export const subscription = createMappedReducer(initialState, {
  ...createRequestLifecycleHandlers({
    fetchActionNameRoot: POST_RECEIPT_TO_REV_CAT,
  }),
  ...createRequestLifecycleHandlers({
    fetchActionNameRoot: LINK_EXISTING_SUBSCRIPTION,
  }),
  ...createRequestLifecycleHandlers({ fetchActionNameRoot: GET_SIGNATURE }),
  ...createRequestLifecycleHandlers({
    fetchActionNameRoot: CREATE_SUBSCRIPTION,
  }),
  ...createRequestLifecycleHandlers({
    fetchActionNameRoot: CREATE_SUBSCRIPTION_USER,
  }),
  ...createRequestLifecycleHandlers({
    fetchActionNameRoot: CREATE_SETUP_INTENT,
  }),
  ...createRequestLifecycleHandlers({
    fetchActionNameRoot: RENEW_SUBSCRIPTION,
  }),
  [fulfilled(GET_SUBSCRIPTION)](state, action) {
    return update(state, {
      isFetching: { $set: false },
      current: { $set: get(action, 'payload.content') },
    });
  },
  [rejected(GET_SUBSCRIPTION)](state) {
    return {
      ...state,
      isFetching: false,
      current: {
        hasFailed: true,
      },
    };
  },
  [pending(GET_SUBSCRIPTION)](state) {
    return {
      ...state,
      isFetching: true,
    };
  },
  [pending(CANCEL_SUBSCRIPTION)](state) {
    return {
      ...state,
      cancellationStatus: asyncStatuses.pending,
    };
  },
  [fulfilled(CANCEL_SUBSCRIPTION)](state, action) {
    return {
      ...state,
      cancellationStatus: asyncStatuses.fulfilled,
      current: action?.payload?.data,
      coupon: undefined,
    };
  },
  [rejected(CANCEL_SUBSCRIPTION)](state) {
    return {
      ...state,
      cancellationStatus: asyncStatuses.rejected,
    };
  },
  [fulfilled(RENEW_SUBSCRIPTION)](state, action) {
    return update(state, {
      [action.meta.contentId]: {
        $merge: {
          loadedTime: action.payload.loadedTime,
          isFetching: false,
        },
      },
      current: { $set: get(action, 'payload.content') },
      paymentInfo: { $set: {} },
      $unset: ['cancellationStatus'],
    });
  },
  [fulfilled(CREATE_SUBSCRIPTION)](state, action) {
    return update(state, {
      [action.meta.contentId]: {
        $merge: {
          loadedTime: action.payload.loadedTime,
          isFetching: false,
        },
      },
      address: {
        $set: {},
      },
      current: { $set: get(action, 'payload.content') },
      paymentInfo: { $set: {} },
    });
  },
  [fulfilled(LOGOUT)]() {
    return initialState;
  },
  [SET_LOCATION](state, action) {
    return update(state, {
      paymentInfo: {
        $merge: {
          country: action.country,
          state: action.state,
        },
      },
    });
  },
  [SET_LOCATION_OPTIONS](state, action) {
    return update(state, {
      paymentInfo: {
        $merge: {
          options: action.locations,
        },
      },
    });
  },
  [GET_LOCATION](state, action) {
    return update(state, {
      paymentInfo: {
        $set: {
          postalCode: action.postalCode,
        },
      },
    });
  },
  [LOCATION_NOT_FOUND](state) {
    return update(state, {
      paymentInfo: {
        $merge: {
          enableManualSelection: true,
        },
      },
    });
  },
  [pending(FETCH_COUPON)](state) {
    return {
      ...state,
      coupon: {
        ...(state.coupon || {}),
        isFetching: true,
      },
    };
  },
  [rejected(FETCH_COUPON)](state, action) {
    return update(state, {
      coupon: {
        $set: {
          isFetching: false,
          hasFailed: true,
          errorStatusCode: get(action, 'payload.response.status'),
          errorStatusText: get(action, 'payload.response.statusText'),
          status: get(action, 'payload.response.data'),
        },
      },
    });
  },
  [fulfilled(FETCH_COUPON)](state, action) {
    return update(state, {
      coupon: {
        $set: {
          isFetching: false,
          loadedTime: action.payload.loadedTime,
          ...action.payload.data,
          isProductDefaultCoupon: action.meta?.isProductDefaultCoupon,
        },
      },
    });
  },
  [fulfilled(CREATE_SUBSCRIPTION_USER)](state, action) {
    return update(state, {
      subProviderInfo: {
        $merge: {
          isFetching: false,
          loadedTime: action.payload.loadedTime,
          userInfo: {
            ...action.payload.content,
          },
        },
      },
    });
  },
  [fulfilled(CREATE_SETUP_INTENT)](state, action) {
    return update(state, {
      subProviderInfo: {
        $merge: {
          isFetching: false,
          loadedTime: action.payload.loadedTime,
          intentInfo: {
            ...action.payload.content,
          },
        },
      },
    });
  },
  [CLEAR_SUBSCRIPTION_CURRENT_STATE](state) {
    return update(state, {
      current: {
        $set: {},
      },
    });
  },
  [UPDATE_LOCAL_CUSTOMER_ADDRESS](state, action) {
    return update(state, {
      address: {
        $set: action.address,
      },
    });
  },
  [CLEAR_SETUP_INTENT_STATE](state) {
    return update(state, {
      subProviderInfo: {
        $merge: {
          intentInfo: {},
        },
      },
    });
  },
});

import { FETCH_USER } from 'src/common/actions/users';

import createContainerNavigationHandlers from './utils/createContainerNavigationHandlers';
import createMappedReducer from './utils/createMappedReducer';
import createRequestLifecycleHandlers from './utils/createRequestLifecycleHandlers';

export const users = createMappedReducer(
  {},
  {
    ...createContainerNavigationHandlers(),
    ...createRequestLifecycleHandlers({ fetchActionNameRoot: FETCH_USER }),
  },
);

import { combineReducers } from 'redux';
import { reducer as reduxAsyncConnect } from 'redux-connect';

import { app } from './app';
import { audioClips } from './audioClips';
import { auth } from './auth';
import { categories } from './browse';
import { config } from './config';
import { desktop } from './desktop';
import { dialog } from './dialog';
import formReducer from './formReducer';
import { heroCarousel } from './heroCarousel';
import { logging } from './logging';
import { loginDetails } from './loginDetails';
import { me } from './me';
import { messagePopover } from './messagePopover';
import { mint } from './mint';
import { partners } from './partners';
import playbackHistory from './playbackHistory';
import { player } from './player';
import { products } from './products';
import { profiles } from './profile';
import { reporting } from './reporting';
import { search } from './search';
import { subscription } from './subscription';
import { subscriptionFlow } from './subscriptionFlow';
import { users } from './users';

export default combineReducers({
  reduxAsyncConnect,

  // custom reducers
  app,
  audioClips,
  auth,
  categories,
  config,
  desktop,
  dialog,
  form: formReducer,
  heroCarousel,
  loginDetails,
  me,
  messagePopover,
  mint,
  logging,
  partners,
  playbackHistory,
  player,
  products,
  profiles,
  reporting,
  search,
  subscription,
  subscriptionFlow,
  users,
});

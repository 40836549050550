import get from 'lodash/get';
import { FETCH_CAROUSEL_ITEMS } from 'src/common/actions/heroCarousel';
import {
  CAROUSEL_MAPVIEW_GUIDEID,
  CAROUSEL_UPSELL_GUIDEID,
} from 'src/common/constants/heroCarousel';
import { LINE_BREAK_INSTRUCTION } from 'src/common/constants/localizations/shared';
import getGuideItemPathname from 'src/common/utils/guideItem/getGuideItemPathname';
import defaultCarouselElements from 'src/data/landing/carouselElements.json';
import { fulfilled, pending, rejected } from './utils/asyncActionNameSuffixes';
import createMappedReducer from './utils/createMappedReducer';

export const heroCarousel = createMappedReducer(
  {},
  {
    [pending(FETCH_CAROUSEL_ITEMS)](state) {
      return {
        ...state,
        isFetching: true,
      };
    },
    [rejected(FETCH_CAROUSEL_ITEMS)](state, action) {
      return {
        ...state,
        carouselItems: defaultCarouselElements,
        loadedTime: action.payload.loadedTime,
        isFetching: false,
      };
    },
    [fulfilled(FETCH_CAROUSEL_ITEMS)](state, action) {
      // we only want a max of 5 items for our carousel
      const containerItems = get(
        action,
        'payload.content.containerItems',
        [],
      ).slice(0, 5);

      const carouselItems = containerItems.map((guideItem) => {
        const parentProgram = get(
          guideItem,
          'guideItem.properties.parentProgram',
          {},
        );
        const guideItemText = get(guideItem, 'title', '');
        const strings = guideItemText.split(LINE_BREAK_INSTRUCTION);
        const bgColor = get(guideItem, 'presentation.backgroundColor', '');
        const actions = get(guideItem, 'actions', {});
        const imageUrl = get(
          guideItem,
          'properties.landscapeImage.imageUrl',
          '',
        );
        let tinyImgUrl = get(guideItem, 'image', '');
        let href = getGuideItemPathname(guideItem, parentProgram);
        let isCarouselUpsell = false;

        if (get(guideItem, 'guideId', '') === CAROUSEL_UPSELL_GUIDEID) {
          tinyImgUrl = null;
          isCarouselUpsell = true;
          href = '/subscribe/?vt=su&source=landingpage.carouselUpsell';
        } else if (get(guideItem, 'guideId', '') === CAROUSEL_MAPVIEW_GUIDEID) {
          isCarouselUpsell = false;
          href = '/explorer/?source=landingpage.map';
        }

        return {
          guideItem: actions,
          line1: get(strings, [0]),
          line2: get(strings, [1]),
          line3: get(strings, [2]),
          href,
          bgColor,
          imageUrl,
          tinyImgUrl,
          isCarouselUpsell,
        };
      });

      return {
        ...state,
        carouselItems,
        loadedTime: action.payload.loadedTime,
        isFetching: false,
      };
    },
  },
);

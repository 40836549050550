import update from 'immutability-helper';
import findIndex from 'lodash/findIndex';
import forEach from 'lodash/forEach';
import get from 'lodash/get';
import has from 'lodash/has';
import isBoolean from 'lodash/isBoolean';
import mapValues from 'lodash/mapValues';

import {
  CHANGE_USERNAME,
  LOGOUT,
  UNAUTHENTICATE,
} from 'src/common/actions/auth';
import {
  ADD_FOLDER,
  ADD_ME_DEVICE,
  DELETE_ME_DEVICE,
  FETCH_FAVORITES_FOLDER,
  FETCH_ME,
  FETCH_ME_DEVICES,
  FETCH_ME_WITH_FAVORITES,
  MOVE_FAVORITE_TO_FOLDER,
  REORDER_FAVORITES,
  SAVE_ME,
  SET_DEFAULT,
} from 'src/common/actions/me';
import { move } from 'src/common/utils/immutableArray';
import {
  currentSubscriptionStatus,
  premiumSubscriptionStatus,
} from 'src/common/utils/subscription/subscriptionStatus';
import {
  FAVORITE_CONTAINER_ITEM,
  FAVORITE_CURRENTLY_PLAYING,
  FAVORITE_PROFILE,
  REMOVE_FOLDER,
  UNFAVORITE_CONTAINER_ITEM,
  UNFAVORITE_CURRENTLY_PLAYING,
  UNFAVORITE_PROFILE,
} from '../actions/favorites';
import {
  CREATE_SUBSCRIPTION,
  RENEW_SUBSCRIPTION,
} from '../actions/subscription';
import { NOW_PLAYING_CHANGED } from '../actions/tuner';
import { fulfilled, pending, rejected } from './utils/asyncActionNameSuffixes';

import createContainerNavigationHandlers from './utils/createContainerNavigationHandlers';
import createMappedReducer from './utils/createMappedReducer';
import createRequestLifecycleHandlers from './utils/createRequestLifecycleHandlers';

import createFavoriteLifecycleHandlers from './utils/createFavoriteLifecycleHandlers';

function getIndexName(isCustom) {
  return isCustom
    ? 'customFavoriteFoldersIndexed'
    : 'defaultFavoriteFoldersIndexed';
}

function removeItem(state, { index, folder }) {
  return update(state, {
    details: {
      [getIndexName(folder.isCustom)]: {
        [folder.folderId]: {
          children: { $splice: [[index, 1]] },
        },
      },
    },
  });
}

/**
 * util function to handle the favorite state update
 * @param state {object}
 * @param guideId {string} - guideId to favorite/unfavorite
 * @param shouldFavorite {boolean} - favorite state to set for the guideItem
 * @returns {object} updated state
 */
export function updateChangedFavoritesState(state, guideId, shouldFavorite) {
  if (
    !guideId ||
    !isBoolean(shouldFavorite) ||
    get(state, ['changedFavorites', guideId]) === shouldFavorite
  ) {
    return state;
  }

  return update(state, {
    changedFavorites: {
      [guideId]: {
        $set: shouldFavorite,
      },
    },
  });
}

/**
 * util function that gets the guideId from a favorite action
 * @param {object} action
 * @returns {string} guideId
 */
export const getGuideIdFromFavoriteAction = (action) =>
  action?.meta?.guideItem?.guideId || action?.meta?.guideId;

function reorderFavoritesPending(state, action) {
  const { folder, fromIndex, toIndex } = action.meta;

  return update(state, {
    details: {
      [getIndexName(folder.isCustom)]: {
        [folder.folderId]: {
          children: {
            $apply: (children) => move(children, fromIndex, toIndex),
          },
        },
      },
    },
  });
}

function moveFavoriteToFolderPending(state, action) {
  const { fromIndex, fromFolder } = action.meta;

  return removeItem(state, {
    index: fromIndex,
    folder: fromFolder,
  });
}

function handleFavoriteGuideIdUpdate(
  state,
  guideId,
  switchDestinationGuideId,
  shouldFavorite,
) {
  let updatedState = state;
  updatedState = updateChangedFavoritesState(
    updatedState,
    guideId,
    shouldFavorite,
  );

  // switch action is for the dual profiles (free/premium stream)
  // and we want to keep both in sync
  if (switchDestinationGuideId && switchDestinationGuideId !== guideId) {
    return updateChangedFavoritesState(
      updatedState,
      switchDestinationGuideId,
      shouldFavorite,
    );
  }

  return updatedState;
}

function favoriteProfilePending(state, action) {
  const guideId = getGuideIdFromFavoriteAction(action);
  const { switchDestinationGuideId } = action?.meta || {};

  return handleFavoriteGuideIdUpdate(
    state,
    guideId,
    switchDestinationGuideId,
    true,
  );
}

function unfavoriteProfilePending(state, action) {
  const { index, guideItem, folder, switchDestinationGuideId } =
    action.meta || {};
  const guideId = getGuideIdFromFavoriteAction(action);

  const itemPath = [
    'details',
    folder ? getIndexName(folder.isCustom) : null,
    get(folder, 'folderId'),
    'children',
    index,
  ];

  const updatedState = handleFavoriteGuideIdUpdate(
    state,
    guideId,
    switchDestinationGuideId,
    false,
  );

  return !get(updatedState, itemPath)
    ? updatedState
    : removeItem(updatedState, {
        index,
        guideItem,
        folder,
      });
}

function fetchFavoritesFolderPending(state, action) {
  const { folder } = action.meta;
  return update(state, {
    details: {
      customFavoriteFoldersIndexed: {
        [folder.folderId]: {
          $merge: { isFetching: true },
        },
      },
    },
  });
}

function fetchFavoritesFolderFulfilled(state, action) {
  const { folder } = action.meta;
  return update(state, {
    details: {
      customFavoriteFoldersIndexed: {
        [folder.folderId]: {
          $merge: {
            isFetching: false,
            children: action.payload.containerItems,
          },
        },
      },
    },
  });
}

function addFolder(state, action) {
  const {
    payload: { folderId },
    meta: { userId, name },
  } = action;

  const metaFolder = {
    title: name,
    isCustom: true,
    isMeta: true,
    userId,
    folderId,
  };

  return update(state, {
    details: {
      customFavoriteFoldersOrdered: { $push: [folderId] },
      customFavoriteFoldersIndexed: {
        [folderId]: {
          $set: {
            properties: {
              favoriteFolder: {}, // needed for setDefaultFulfilled update
            },
            ...metaFolder,
          },
        },
      },
    },
  });
}

function deleteFolder(state, action) {
  const { folderId } = action?.meta?.folder || {};

  if (!folderId) {
    return state;
  }

  const newCustomFavoriteFoldersOrdered =
    state?.details?.customFavoriteFoldersOrdered?.filter(
      (id) => id.toString() !== folderId.toString(),
    ) || [];

  return update(state, {
    details: {
      customFavoriteFoldersOrdered: { $set: newCustomFavoriteFoldersOrdered },
      customFavoriteFoldersIndexed: {
        $unset: [folderId],
      },
    },
  });
}

function setDefaultFulfilled(state, action) {
  const {
    folder: { folderId },
  } = action.meta;

  const resetDefaultsUpdater = mapValues(
    state.details.customFavoriteFoldersIndexed,
    (_, _folderId) => ({
      properties: {
        favoriteFolder: {
          isRoot: { $set: _folderId === folderId },
        },
      },
    }),
  );

  return update(state, {
    details: {
      customFavoriteFoldersIndexed: resetDefaultsUpdater,
    },
  });
}

function setUserSubscriptionStatus(state, action) {
  if (action?.payload?.content?.status === currentSubscriptionStatus.active) {
    return update(state, {
      details: {
        $merge: {
          subscriptionStatus: premiumSubscriptionStatus.active,
          subscriptionProviderName: action?.payload?.content?.providerName,
          subscriptionKey: action?.payload?.content?.subItemId,
        },
      },
    });
  }
  return state;
}

function clearUser(state) {
  return update(state, {
    details: { $set: {} },
    devices: { $set: {} },
  });
}

function addMeDevicePending(state) {
  return update(state, {
    devices: {
      $merge: {
        isFetching: true,
      },
    },
  });
}

function addMeDeviceFulfilled(state, action) {
  return update(state, {
    devices: {
      list: { $push: [action.payload.content] },
      isFetching: { $set: false },
    },
  });
}

function addMeDeviceRejected(state) {
  return update(state, {
    devices: {
      isFetching: { $set: false },
    },
  });
}

function deleteMeDevicePending(state) {
  return update(state, {
    devices: {
      $merge: {
        isFetching: true,
      },
    },
  });
}

function deleteMeDeviceFulfilled(state, action) {
  const listIndex = findIndex(state.devices.list, { id: action.meta.id });

  return update(state, {
    devices: {
      list: { $splice: [[listIndex, 1]] },
      isFetching: { $set: false },
    },
  });
}

function deleteMeDeviceRejected(state) {
  return update(state, {
    devices: {
      isFetching: { $set: false },
    },
  });
}

/**
 * handles now playing event data coming from the now playing poller in the tuner
 * updates changedFavorites states with the data
 * @param state
 * @param action
 * @returns {object}
 */
function nowPlayingChangedHandler(state, action) {
  let updatedState = state;
  const followOptions = get(action, 'nowPlaying.followOptions', []);
  const switchDestinationGuideId = get(
    action,
    'nowPlaying.switchAction.destinationGuideId',
  );

  forEach(followOptions, (option) => {
    const optionGuideId = get(option, 'guideId');
    const isFollowingOption = get(option, 'isFollowing', false);
    const primaryGuideId = get(action, 'nowPlaying.primaryGuideId');
    const isOptionGuideIdInState = has(state.changedFavorites, optionGuideId);

    // if its a dual profile (free/premium) and we have made a change to one of them
    // then we need to keep both of them in sync
    if (
      primaryGuideId === optionGuideId &&
      switchDestinationGuideId &&
      (isOptionGuideIdInState ||
        has(state.changedFavorites, switchDestinationGuideId))
    ) {
      updatedState = updateChangedFavoritesState(
        updatedState,
        optionGuideId,
        isFollowingOption,
      );
      updatedState = updateChangedFavoritesState(
        updatedState,
        switchDestinationGuideId,
        isFollowingOption,
      );
      return;
    }

    if (isOptionGuideIdInState) {
      updatedState = updateChangedFavoritesState(
        updatedState,
        optionGuideId,
        isFollowingOption,
      );
    }
  });

  return updatedState;
}

/**
 * handles favorite actions which occurred from the player (currently playing)
 * @param shouldFavorite {boolean} - should this action favorite or unfavorite the item
 * @returns {function}
 */
function currentlyPlayingFavoriteHandler(shouldFavorite) {
  return (state, action) => {
    const { guideId, nowPlaying } = get(action, 'meta', {});

    const updatedState = updateChangedFavoritesState(
      state,
      guideId,
      shouldFavorite,
    );

    // switch action is for the dual profiles (free/premium stream)
    // and we want to keep both in sync
    const switchDestinationGuideId = get(
      nowPlaying,
      'switchAction.destinationGuideId',
    );
    if (switchDestinationGuideId) {
      return updateChangedFavoritesState(
        updatedState,
        switchDestinationGuideId,
        shouldFavorite,
      );
    }

    return updatedState;
  };
}

const initialState = {
  details: {},
  changedFavorites: {},
};

export const me = createMappedReducer(initialState, {
  ...createRequestLifecycleHandlers({ fetchActionNameRoot: FETCH_ME }),
  ...createRequestLifecycleHandlers({
    fetchActionNameRoot: FETCH_ME_WITH_FAVORITES,
  }),
  ...createRequestLifecycleHandlers({ fetchActionNameRoot: SAVE_ME }),
  ...createRequestLifecycleHandlers({ fetchActionNameRoot: FETCH_ME_DEVICES }),
  ...createContainerNavigationHandlers(),
  [pending(FETCH_FAVORITES_FOLDER)]: fetchFavoritesFolderPending,
  [fulfilled(FETCH_FAVORITES_FOLDER)]: fetchFavoritesFolderFulfilled,
  [pending(REORDER_FAVORITES)]: reorderFavoritesPending,
  [pending(MOVE_FAVORITE_TO_FOLDER)]: moveFavoriteToFolderPending,
  [fulfilled(ADD_FOLDER)]: addFolder,
  [fulfilled(SET_DEFAULT)]: setDefaultFulfilled,
  [fulfilled(CREATE_SUBSCRIPTION)]: setUserSubscriptionStatus,
  [fulfilled(RENEW_SUBSCRIPTION)]: setUserSubscriptionStatus,
  [fulfilled(LOGOUT)]: clearUser,
  [UNAUTHENTICATE]: clearUser,
  [pending(ADD_ME_DEVICE)]: addMeDevicePending,
  [fulfilled(ADD_ME_DEVICE)]: addMeDeviceFulfilled,
  [rejected(ADD_ME_DEVICE)]: addMeDeviceRejected,
  [pending(DELETE_ME_DEVICE)]: deleteMeDevicePending,
  [fulfilled(DELETE_ME_DEVICE)]: deleteMeDeviceFulfilled,
  [rejected(DELETE_ME_DEVICE)]: deleteMeDeviceRejected,
  [fulfilled(CHANGE_USERNAME)]: (state, action) =>
    update(state, {
      details: {
        userName: { $set: action.meta },
      },
    }),
  ...createFavoriteLifecycleHandlers(FAVORITE_PROFILE, true),
  ...createFavoriteLifecycleHandlers(UNFAVORITE_PROFILE, false),
  [pending(FAVORITE_PROFILE)]: favoriteProfilePending,
  [pending(UNFAVORITE_PROFILE)]: unfavoriteProfilePending,
  [fulfilled(REMOVE_FOLDER)]: deleteFolder,
  ...createFavoriteLifecycleHandlers(FAVORITE_CONTAINER_ITEM, true),
  ...createFavoriteLifecycleHandlers(UNFAVORITE_CONTAINER_ITEM, false),
  [pending(FAVORITE_CURRENTLY_PLAYING)]: currentlyPlayingFavoriteHandler(true),
  [fulfilled(FAVORITE_CURRENTLY_PLAYING)]:
    currentlyPlayingFavoriteHandler(true),
  [rejected(FAVORITE_CURRENTLY_PLAYING)]:
    currentlyPlayingFavoriteHandler(false),
  [pending(UNFAVORITE_CURRENTLY_PLAYING)]:
    currentlyPlayingFavoriteHandler(false),
  [fulfilled(UNFAVORITE_CURRENTLY_PLAYING)]:
    currentlyPlayingFavoriteHandler(false),
  [rejected(UNFAVORITE_CURRENTLY_PLAYING)]:
    currentlyPlayingFavoriteHandler(true),
  [NOW_PLAYING_CHANGED]: nowPlayingChangedHandler,
});

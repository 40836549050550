import update from 'immutability-helper';
import { fulfilled, pending, rejected } from './asyncActionNameSuffixes';

function getFetchingStatusHandlers(actionType) {
  return {
    [pending(actionType)](state) {
      return update(state, { isFetching: { $set: true } });
    },
    [rejected(actionType)](state) {
      return update(state, { isFetching: { $set: false } });
    },
    [fulfilled(actionType)](state) {
      return update(state, { isFetching: { $set: false } });
    },
  };
}

export function mapActionTypesToFetchingStatusHandlers(actionTypes) {
  return actionTypes.reduce(
    (handlers, actionType) =>
      Object.assign(handlers, getFetchingStatusHandlers(actionType)),
    {},
  );
}

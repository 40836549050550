import {
  getGuideIdFromFavoriteAction,
  updateChangedFavoritesState,
} from '../me';
import { fulfilled, pending, rejected } from './asyncActionNameSuffixes';

/**
 *  util to create request lifecycle handlers (pending, fulfilled, rejected) for favorite actions
 * @param actionName {string} - action name
 * @param isFavorited {boolean} - should action favorite or unfavorite the item
 * @returns {object} updated state
 */
export default function createFavoriteLifecycleHandlers(
  actionName,
  isFavorited,
) {
  return {
    [pending(actionName)](state, action) {
      const guideId = getGuideIdFromFavoriteAction(action);

      return updateChangedFavoritesState(state, guideId, isFavorited);
    },
    [rejected(actionName)](state, action) {
      const guideId = getGuideIdFromFavoriteAction(action);

      return updateChangedFavoritesState(state, guideId, !isFavorited);
    },
    [fulfilled(actionName)](state, action) {
      const guideId = getGuideIdFromFavoriteAction(action);

      return updateChangedFavoritesState(state, guideId, isFavorited);
    },
  };
}

import update from 'immutability-helper';
import createMappedReducer from 'src/common/reducers/utils/createMappedReducer';
import {
  REMEMBER_CONTENT_CARD_WITH_IMPRESSION_REPORTED,
  UPDATE_CARDS_VALIDATION_ERRORS,
} from '../actions/logging';

const initialState = {
  contentCards: {
    impressions: {},
    errors: [],
    reportedErrors: {},
  },
};

export const logging = createMappedReducer(initialState, {
  [REMEMBER_CONTENT_CARD_WITH_IMPRESSION_REPORTED](state, action) {
    return update(state, {
      contentCards: {
        impressions: { [action.contentCardId]: { $set: true } },
      },
    });
  },
  [UPDATE_CARDS_VALIDATION_ERRORS](state, action) {
    const filteredErrors = action.errors.filter(
      ({ id }) => !state.contentCards.reportedErrors[id],
    );

    return update(state, {
      contentCards: {
        errors: { $set: filteredErrors },
        reportedErrors: {
          $merge: Object.fromEntries(
            filteredErrors.map(({ id }) => [id, true]),
          ),
        },
      },
    });
  },
});

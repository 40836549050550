import update from 'immutability-helper';
import { trialUnitTypes } from 'src/common/constants/subscription/coupon';
import createMappedReducer from 'src/common/reducers/utils/createMappedReducer';
import { sortByBillingPeriod } from 'src/common/utils/subscription/billingPeriod';
import {
  GET_PRODUCTS_BY_SKUS,
  PRODUCTS_TRANSFORM,
  UPDATE_SKU_RENEW_STATUSES,
} from '../actions/products';
import { isMonthlyTrial } from '../components/utils/subscriptionProductDetails';
import filterProductsBySkus from '../utils/subscription/filterProductsBySkus';
import findProductWithSameBillingPeriod from '../utils/subscription/findProductWithSameBillingPeriod';
import { fulfilled } from './utils/asyncActionNameSuffixes';
import createRequestLifecycleHandlers from './utils/createRequestLifecycleHandlers';

const getClientProductModel = (product = {}, standardProducts) => {
  const {
    sku,
    currencyCode,
    price,
    priceId,
    isFree,
    freeTrialLength,
    freeTrialPeriod,
    formattedPrice,
    formattedDiscountedPrice,
    hasSetupFee,
    formattedSetupPrice,
    currencies,
    billingPeriod,
    requiresBillingInfo,
    discountFreeTrialLength,
    discountDurationInMonths,
    discountDurationType,
    isUsingDefaultCoupon = false,
  } = product;
  const standardProduct =
    findProductWithSameBillingPeriod(standardProducts, product) || {};
  const { price: stdUnformattedPrice, formattedPrice: stdFormattedPrice } =
    standardProduct;
  const unformattedPrice =
    product?.discountedCurrencies?.[currencyCode] || price;

  return {
    priceId,
    sku,
    billingPeriod,
    currencies,
    currencyCode,
    requiresBillingInfo,
    hasCouponBeenApplied: !!(
      formattedDiscountedPrice || discountFreeTrialLength
    ),
    freeTrialLength: discountFreeTrialLength || freeTrialLength,
    price: formattedDiscountedPrice || formattedPrice,
    setupPrice: hasSetupFee ? formattedSetupPrice : 0,
    isFree: !!isFree,
    isMonthlyTrial:
      isMonthlyTrial(product) || freeTrialPeriod === trialUnitTypes.month,
    hasTrial:
      Number.parseInt(freeTrialLength, 10) !== 0 ||
      !!(
        discountFreeTrialLength &&
        Number.parseInt(discountFreeTrialLength, 10) !== 0
      ),
    standardProductPrice: stdFormattedPrice || formattedPrice,
    hasPriceDiscount: unformattedPrice < stdUnformattedPrice,
    hasSetupFee,
    discountDurationInMonths,
    discountDurationType,
    isUsingDefaultCoupon,
  };
};

export const products = createMappedReducer(
  {},
  {
    ...createRequestLifecycleHandlers({
      fetchActionNameRoot: GET_PRODUCTS_BY_SKUS,
    }),
    [fulfilled(GET_PRODUCTS_BY_SKUS)](state) {
      return state;
    },
    [PRODUCTS_TRANSFORM](state, action) {
      const allProducts = {};
      const finalProducts = {};

      action.content?.forEach((item) => {
        allProducts[item.sku] = item;
      });

      const { skuMeta } = action.meta;
      const standardProducts = filterProductsBySkus(
        allProducts,
        skuMeta.defaultSkus,
      );
      const productsFromQuery = filterProductsBySkus(
        allProducts,
        skuMeta.fromQuery,
      );
      const productsToUse = productsFromQuery.length
        ? productsFromQuery
        : standardProducts;

      productsToUse.sort(sortByBillingPeriod).forEach((product) => {
        finalProducts[product.sku] = {
          ...getClientProductModel(product, standardProducts),
          isRenew: skuMeta.isRenew,
        };
      });

      return finalProducts;
    },
    [UPDATE_SKU_RENEW_STATUSES](state, action) {
      return action?.meta?.skus?.reduce(
        (nextState, sku) =>
          update(nextState, {
            [sku]: {
              isRenew: { $set: action?.meta?.isRenew },
            },
          }),
        state,
      );
    },
  },
);

import update from 'immutability-helper';
import filter from 'lodash/filter';

import { DESKTOP_GOOGLE_AUTH } from '../actions/auth';
import {
  CLOSE_DESKTOP_DIALOG,
  CLOSE_DOWNLOAD_APP_DIALOG,
  CLOSE_ERROR_DIALOG,
  CLOSE_INTEREST_SELECTOR_DIALOG,
  CLOSE_IN_APP_MESSAGE_DIALOG,
  CLOSE_LINK_WITH_ALEXA_DIALOG,
  CLOSE_MESSAGE_OF_THE_DAY_DIALOG,
  CLOSE_NOW_PLAYING_DIALOG,
  CLOSE_POPOUT_PLAYER_DIALOG,
  CLOSE_SHARE_DIALOG,
  CLOSE_TEXT_ME_THE_APP_DIALOG,
  CLOSE_UPSELL_DIALOG,
  CLOSE_USER_REGISTRATION_DIALOG,
  FETCH_NOW_PLAYING_CATEGORY,
  OPEN_DESKTOP_DIALOG,
  OPEN_DOWNLOAD_APP_DIALOG,
  OPEN_ERROR_DIALOG,
  OPEN_INTEREST_SELECTOR_DIALOG,
  OPEN_IN_APP_MESSAGE_DIALOG,
  OPEN_LINK_WITH_ALEXA_DIALOG,
  OPEN_MESSAGE_OF_THE_DAY_DIALOG,
  OPEN_NOW_PLAYING_DIALOG,
  OPEN_POPOUT_PLAYER_DIALOG,
  OPEN_SHARE_DIALOG,
  OPEN_TEXT_ME_THE_APP_DIALOG,
  OPEN_UPSELL_DIALOG,
  OPEN_USER_REGISTRATION_DIALOG,
  REQUEST_USER_REGISTRATION_DIALOG_OPEN,
  UPDATE_VIDEO_DIALOG,
} from '../actions/dialog';
import { UPDATE_INTEREST_SELECTOR_ITEMS } from '../actions/favoriteInterests';
import { RECENTS } from '../constants/containerTypes';
import { fulfilled, pending, rejected } from './utils/asyncActionNameSuffixes';
import createMappedReducer from './utils/createMappedReducer';
import createRequestLifecycleHandlers from './utils/createRequestLifecycleHandlers';

const initialState = {
  isUpsellOpen: false,
  requestOpenUpsellDialog: false,
  isTextMeTheAppDialogOpen: false,
  isDownloadAppDialogOpen: false,
  didShowDownloadAppDialog: false,
  shouldInAppMessageDialogOpen: false,
  inAppMessageDialogContent: undefined,
  inAppMessageGuideId: undefined,
  isMessageOfTheDayDialogOpen: false,
  isInterestSelectorDialogOpen: false,
  isDesktopDialogOpen: false,
  isNowPlayingDialogOpen: false,
  isPopoutPlayerDialogOpen: false,
  registrationDialogIsOpen: false,
  registrationDialogOpenRequested: false,
  linkWithAlexaDialogIsOpen: false,
  pendingRegistrationDialogOpenRequest: false,
  registrationDialogView: null,
  isErrorDialogOpen: false,
  isShareDialogOpen: false,
  shareDialogItem: {},
  videoAdDialog: {
    isOpen: false,
  },
};

function mapInfoMessageResponse(action) {
  const { title, subtitle, actions } =
    action.payload?.data?.items?.[0]?.children?.[0] || {};

  return {
    key: action.meta?.contentId,
    title,
    subtitle,
    buttons: actions?.navigate?.destinations || [],
  };
}

function destroyInAppMessageDialog(state) {
  return update(state, {
    shouldInAppMessageDialogOpen: { $set: false },
    inAppMessageDialogContent: { $set: undefined },
    inAppMessageGuideId: { $set: undefined },
  });
}

export const dialog = createMappedReducer(initialState, {
  ...createRequestLifecycleHandlers({
    fetchActionNameRoot: FETCH_NOW_PLAYING_CATEGORY,
  }),
  [fulfilled(FETCH_NOW_PLAYING_CATEGORY)](state, action) {
    let payloadContentToUse = action.payload?.content || {};

    let recentsContainer;
    if (action.meta?.isNPEnabled) {
      const containerItems = payloadContentToUse.containerItems || [];
      const newContainerItems = filter(containerItems, (item) => {
        if (item.containerType === RECENTS) {
          recentsContainer = item;
          return false;
        }
        return true;
      });
      payloadContentToUse = {
        ...payloadContentToUse,
        containerItems: newContainerItems,
      };
    }
    return update(state, {
      recents: recentsContainer ? { $set: recentsContainer } : { $set: {} },
      [action.meta?.contentId]: {
        $merge: {
          ...payloadContentToUse,
          loadedTime: action.payload?.loadedTime,
          isFetching: false,
        },
        $unset: ['hasFailed'],
      },
    });
  },
  [OPEN_UPSELL_DIALOG](state, action) {
    return {
      ...state,
      isUpsellOpen: true,
      upsellLabel: action.label,
      upsellGuideId: action.guideId,
      topicGuideId: action.topicGuideId,
      products: action.products,
      image: action.image,
      title: action.title,
    };
  },
  [CLOSE_UPSELL_DIALOG](state) {
    return {
      ...state,
      isUpsellOpen: false,
    };
  },
  [OPEN_DOWNLOAD_APP_DIALOG](state) {
    return {
      ...state,
      isDownloadAppDialogOpen: true,
      didShowDownloadAppDialog: true,
    };
  },
  [CLOSE_DOWNLOAD_APP_DIALOG](state) {
    return {
      ...state,
      isDownloadAppDialogOpen: false,
    };
  },
  [CLOSE_IN_APP_MESSAGE_DIALOG]: destroyInAppMessageDialog,
  [pending(OPEN_IN_APP_MESSAGE_DIALOG)]: destroyInAppMessageDialog,
  [rejected(OPEN_IN_APP_MESSAGE_DIALOG)]: destroyInAppMessageDialog,
  [fulfilled(OPEN_IN_APP_MESSAGE_DIALOG)](state, action) {
    return update(state, {
      shouldInAppMessageDialogOpen: { $set: true },
      inAppMessageDialogContent: { $set: mapInfoMessageResponse(action) },
      inAppMessageGuideId: { $set: action.meta?.guideId },
    });
  },
  [OPEN_TEXT_ME_THE_APP_DIALOG](state) {
    return update(state, {
      isTextMeTheAppDialogOpen: { $set: true },
    });
  },
  [CLOSE_TEXT_ME_THE_APP_DIALOG](state) {
    return update(state, {
      isTextMeTheAppDialogOpen: { $set: false },
    });
  },
  [OPEN_DESKTOP_DIALOG](state) {
    return update(state, {
      isDesktopDialogOpen: { $set: true },
    });
  },
  [CLOSE_DESKTOP_DIALOG](state) {
    return update(state, {
      isDesktopDialogOpen: { $set: false },
    });
  },
  [OPEN_NOW_PLAYING_DIALOG](state) {
    return update(state, {
      isNowPlayingDialogOpen: { $set: true },
    });
  },
  [CLOSE_NOW_PLAYING_DIALOG](state) {
    return update(state, {
      isNowPlayingDialogOpen: { $set: false },
    });
  },
  [OPEN_POPOUT_PLAYER_DIALOG](state) {
    return update(state, {
      isPopoutPlayerDialogOpen: { $set: true },
    });
  },
  [CLOSE_POPOUT_PLAYER_DIALOG](state) {
    return update(state, {
      isPopoutPlayerDialogOpen: { $set: false },
    });
  },
  [OPEN_USER_REGISTRATION_DIALOG](state) {
    return update(state, {
      registrationDialogIsOpen: { $set: true },
      registrationDialogOpenRequested: { $set: false },
    });
  },
  [CLOSE_USER_REGISTRATION_DIALOG](state) {
    return update(state, {
      registrationDialogIsOpen: { $set: false },
      registrationDialogView: { $set: null },
    });
  },
  [REQUEST_USER_REGISTRATION_DIALOG_OPEN](state, action) {
    return update(state, {
      registrationDialogOpenRequested: { $set: true },
      pendingRegistrationDialogOpenRequest: { $set: false },

      // If there is a view currently set from a previous pending
      // open request then use that one, otherwise use the current
      // action's view.
      registrationDialogView: {
        $apply: (currentView) => currentView || action.view,
      },
    });
  },
  [pending(REQUEST_USER_REGISTRATION_DIALOG_OPEN)](state, action) {
    return update(state, {
      pendingRegistrationDialogOpenRequest: { $set: true },
      registrationDialogView: { $set: action.view },
    });
  },
  [OPEN_LINK_WITH_ALEXA_DIALOG](state) {
    return update(state, {
      linkWithAlexaDialogIsOpen: { $set: true },
    });
  },
  [CLOSE_LINK_WITH_ALEXA_DIALOG](state) {
    return update(state, {
      linkWithAlexaDialogIsOpen: { $set: false },
    });
  },
  [OPEN_MESSAGE_OF_THE_DAY_DIALOG](state) {
    return update(state, {
      isMessageOfTheDayDialogOpen: { $set: true },
    });
  },
  [CLOSE_MESSAGE_OF_THE_DAY_DIALOG](state) {
    return update(state, {
      isMessageOfTheDayDialogOpen: { $set: false },
    });
  },
  [OPEN_INTEREST_SELECTOR_DIALOG](state, action) {
    return update(state, {
      isInterestSelectorDialogOpen: { $set: true },
      interestSelectorCategoryId: { $set: action.meta?.categoryId },
    });
  },
  [CLOSE_INTEREST_SELECTOR_DIALOG](state) {
    return update(state, {
      isInterestSelectorDialogOpen: { $set: false },
      $unset: ['interestSelectorCategoryId'],
    });
  },
  [OPEN_ERROR_DIALOG](state) {
    return update(state, {
      isErrorDialogOpen: { $set: true },
    });
  },
  [CLOSE_ERROR_DIALOG](state) {
    return update(state, {
      isErrorDialogOpen: { $set: false },
    });
  },
  [rejected(UPDATE_INTEREST_SELECTOR_ITEMS)](state) {
    return update(state, {
      isErrorDialogOpen: { $set: true },
    });
  },
  [rejected(DESKTOP_GOOGLE_AUTH)](state) {
    return update(state, {
      isErrorDialogOpen: { $set: true },
    });
  },
  [pending(DESKTOP_GOOGLE_AUTH)](state) {
    return update(state, {
      isErrorDialogOpen: { $set: false },
    });
  },
  [OPEN_SHARE_DIALOG](state, action) {
    return update(state, {
      isShareDialogOpen: { $set: true },
      shareDialogItem: { $set: action.guideItem },
    });
  },
  [CLOSE_SHARE_DIALOG](state) {
    return update(state, {
      isShareDialogOpen: { $set: false },
      shareDialogItem: { $set: {} },
    });
  },
  [UPDATE_VIDEO_DIALOG](state, action) {
    return update(state, {
      videoAdDialog: { $merge: action.meta },
    });
  },
});

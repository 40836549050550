/*
  This reducer was born out of a need to perform additional manipulations to redux-form state (or
  , rather, the state of specific forms created by redux-form) based on other specific actions.
*/
import { reducer as reduxFormReducer } from 'redux-form';
import { AUTHENTICATION_UNSUCCESSFUL } from '../actions/auth';
import createMappedReducer from './utils/createMappedReducer';

/*
  For sign in/sign up, this bit fixes a situation where we need to show an oauth failure, but need
  to kill any existing form errors for email sign in/sign up.
*/
function handleRejectedOauthAction(state) {
  return {
    ...state,
    // Safe to wipe out this way as this will always be top level error messages for those forms
    // that have top level error messaging.
    error: {},
  };
}

// Exporting for easier testing
export const formPluginSetup = {
  signIn: createMappedReducer(null, {
    [AUTHENTICATION_UNSUCCESSFUL]: handleRejectedOauthAction,
  }),
  signUp: createMappedReducer(null, {
    [AUTHENTICATION_UNSUCCESSFUL]: handleRejectedOauthAction,
  }),
};

export default reduxFormReducer.plugin(formPluginSetup);

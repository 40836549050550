import update from 'immutability-helper';
import get from 'lodash/get';
import {
  FETCH_PROFILE_FOR_SUBSCRIPTION_FLOW,
  RESET_SUBSCRIPTION_FLOW,
} from 'src/common/actions/subscriptionFlow';
import getGuideItemPathname from 'src/common/utils/guideItem/getGuideItemPathname';
import cssVariables from '../styles/variables';
import { fulfilled, pending, rejected } from './utils/asyncActionNameSuffixes';
import createMappedReducer from './utils/createMappedReducer';

const initialState = {
  profile: {},
};

export const subscriptionFlow = createMappedReducer(initialState, {
  [pending(FETCH_PROFILE_FOR_SUBSCRIPTION_FLOW)](state) {
    return update(state, {
      profile: {
        $merge: {
          isFetching: true,
        },
      },
    });
  },
  [rejected(FETCH_PROFILE_FOR_SUBSCRIPTION_FLOW)](state, action) {
    return update(state, {
      profile: {
        $merge: {
          isFetching: false,
          loadedTime: action.payload.loadedTime,
        },
      },
    });
  },
  [fulfilled(FETCH_PROFILE_FOR_SUBSCRIPTION_FLOW)](state, action) {
    const guideItem = action.payload.content;
    const parentProgram = get(guideItem, 'properties.parentProgram');
    const href = getGuideItemPathname(guideItem, parentProgram || {});
    const title = parentProgram
      ? get(parentProgram, 'destinationInfo.seoName', '')
      : get(guideItem, 'properties.seoInfo.title', '');

    const item = {
      bgColor: cssVariables['--grey-3'],
      image: get(guideItem, 'image', null),
      title,
      guideItem,
      href,
    };

    return update(state, {
      profile: {
        $merge: {
          item,
          isFetching: false,
          loadedTime: action.payload.loadedTime,
        },
      },
    });
  },
  [RESET_SUBSCRIPTION_FLOW](state) {
    return update(state, {
      profile: {
        $set: {
          isFetching: false,
        },
      },
    });
  },
});

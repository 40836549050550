import update from 'immutability-helper';
import get from 'lodash/get';
import {
  FETCH_PROFILE,
  FETCH_TOPIC_PROFILE,
  SET_BRAZE_PROFILE_CARDS,
} from 'src/common/actions/profile';
import { fulfilled } from './utils/asyncActionNameSuffixes';
import createContainerNavigationHandlers from './utils/createContainerNavigationHandlers';
import createMappedReducer from './utils/createMappedReducer';
import createRequestLifecycleHandlers from './utils/createRequestLifecycleHandlers';

export const profiles = createMappedReducer(
  {},
  {
    ...createContainerNavigationHandlers(),
    ...createRequestLifecycleHandlers({ fetchActionNameRoot: FETCH_PROFILE }),
    ...createRequestLifecycleHandlers({
      fetchActionNameRoot: FETCH_TOPIC_PROFILE,
    }),

    [fulfilled(FETCH_PROFILE)](state, action) {
      const { categoryToPrepend, contentId } = action.meta;
      const { content, loadedTime } = action.payload;

      if (categoryToPrepend) {
        const profileContainerItems = get(content, 'containerItems', []);
        profileContainerItems.unshift(categoryToPrepend);
      }

      return update(state, {
        [contentId]: {
          $merge: {
            ...content,
            loadedTime,
            isFetching: false,
          },
        },
      });
    },

    [SET_BRAZE_PROFILE_CARDS](state, action) {
      const { guideId, brazeCards } = action.meta || {};

      return update(state, {
        [guideId]: {
          containerItems: {
            $splice: brazeCards.map((card) => [card.index, 0, card]),
          },
        },
      });
    },
  },
);

import update from 'immutability-helper';
import get from 'lodash/get';
import { fulfilled, pending, rejected } from './asyncActionNameSuffixes';

export default function createRequestLifecycleHandlers({
  fetchActionNameRoot,
}) {
  return {
    [pending(fetchActionNameRoot)](state, action) {
      const { contentId } = action.meta;

      return update(state, {
        [contentId]: {
          $set: {
            ...(state[contentId] || {}),
            isFetching: true,
          },
        },
      });
    },
    [rejected(fetchActionNameRoot)](state, action) {
      return update(state, {
        [action.meta.contentId]: {
          $merge: {
            hasFailed: true,
            errorStatusCode: get(action, ['payload', 'response', 'status']),
            errorStatusText: get(action, ['payload', 'response', 'statusText']),
            isFetching: false,
          },
        },
      });
    },
    [fulfilled(fetchActionNameRoot)](state, action) {
      return update(state, {
        [action.meta.contentId]: {
          $merge: {
            ...action.payload.content,
            loadedTime: action.payload.loadedTime,
            isFetching: false,
          },
          $unset: ['hasFailed'],
        },
      });
    },
  };
}

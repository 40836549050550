import update from 'immutability-helper';
import {
  CLEAR_CATEGORY,
  FETCH_CATEGORY,
  SET_BRAZE_CATEGORY_CARDS,
} from 'src/common/actions/browse';
import { fulfilled } from './utils/asyncActionNameSuffixes';
import createContainerNavigationHandlers from './utils/createContainerNavigationHandlers';
import createMappedReducer from './utils/createMappedReducer';
import createRequestLifecycleHandlers from './utils/createRequestLifecycleHandlers';

export const categories = createMappedReducer(
  {},
  {
    ...createRequestLifecycleHandlers({ fetchActionNameRoot: FETCH_CATEGORY }),
    ...createContainerNavigationHandlers(),
    [fulfilled(FETCH_CATEGORY)](state, action) {
      const { contentId } = action.meta || {};
      const { content = {}, loadedTime } = action.payload || {};
      const containerItems = [...(content?.containerItems || [])];
      const currentBrazeContainerItems =
        state[contentId]?.containerItems?.filter(
          (item) => item.brazeContainer,
        ) || [];

      // Prioritize the Braze container items and move the RM items to the row below.
      currentBrazeContainerItems.forEach((card) => {
        containerItems.splice(card.index, 0, card);
      });

      return update(state, {
        [contentId]: {
          $merge: {
            ...content,
            containerItems,
            loadedTime,
            isFetching: false,
          },
          $unset: ['hasFailed'],
        },
      });
    },
    [CLEAR_CATEGORY](state, action) {
      return update(state, {
        $unset: [action.guideId],
      });
    },
    [SET_BRAZE_CATEGORY_CARDS](state, action) {
      const { guideId, brazeCards } = action.meta || {};

      const containerItems = state[guideId]?.containerItems?.filter?.(
        (item) => !item.brazeContainer,
      );

      brazeCards.forEach((card) => {
        containerItems.splice(card.index, 0, card);
      });

      containerItems.forEach((containerItem, containerItemIndex) => {
        // eslint-disable-next-line no-param-reassign
        containerItem.index = containerItemIndex;
        containerItem.children?.forEach((item) => {
          if (item.reporting?.container) {
            // eslint-disable-next-line no-param-reassign
            item.reporting.container.position = containerItemIndex;
          }
        });
      });

      return update(state, {
        [guideId]: {
          $merge: {
            containerItems,
          },
        },
      });
    },
  },
);

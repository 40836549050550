import get from 'lodash/get';
import { FETCH_AUDIO_CLIP_PROFILE } from 'src/common/actions/audioClips';
import { fulfilled } from './utils/asyncActionNameSuffixes';
import createMappedReducer from './utils/createMappedReducer';

export const audioClips = createMappedReducer(
  {},
  {
    [fulfilled(FETCH_AUDIO_CLIP_PROFILE)](state, action) {
      const audioClip = action.payload.data.item;
      const sourceGuideId = get(
        audioClip,
        'properties.audioInfo.sourceGuideId',
      );

      if (sourceGuideId !== action.meta.sourceGuideId) {
        return state;
      }

      return {
        ...state,
        // see src/server/controllers/app/getAppHTTPAction.js for usage of this data
        [audioClip.guideId]: {
          guideId: audioClip.guideId,
          title: audioClip.title,
          subtitle: audioClip.subtitle,
          image: audioClip.image,
        },
      };
    },
  },
);
